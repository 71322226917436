<template>
    <div ref="detailForm" class="section section--form-detail">
        <div class="form-container">
            <FormInput v-if="id" id="id" name="id" labelText="Id" :disabled="true" :value="options.id" />
            <div class="checkbox-container">
                <div class="label">Abilitato</div>
                <label class="switch">
                    <input type="checkbox" :name="name" :class="[inputState, noIcon]"
                        :disabled="(listEdit) ? false : true" :value="checked" v-model="app_access">
                    <span class="slider"><span class="yes">Sì</span></span>
                </label>
            </div>
        </div>

        <div class="form-container">
            <FormInput labelText="Nome*" :disabled="(listEdit) ? false : true" v-model:value="name"
                :errors="v$.name.$errors" />
            <FormInput labelText="Cognome*" :disabled="(listEdit) ? false : true" v-model:value="surname"
                :errors="v$.surname.$errors" />
            <FormInput labelText="Numero Badge" :disabled="(listEdit) ? false : true" v-model:value="badge_number" />
            <FormInput type="email" :disabled="(listEdit) ? false : true" labelText="Email" v-model:value="email" :errors="v$.email.$errors" />
            <FormInput type="tel" :disabled="(listEdit) ? false : true" labelText="Telefono" v-model:value="phone"
                :errors="v$.phone.$errors" />
            <FormInput labelText="Indirizzo" :disabled="(listEdit) ? false : true" v-model:value="address" />
            <FormInput labelText="Città" :disabled="(listEdit) ? false : true" v-model:value="city" />
            <!-- <FormSelect labelText="Tipo di contratto" :disabled="(listEdit) ? false : true" :item-list="salesPointsList" /> -->
            <div class="input-wrapper">
                <div class="checkbox-container">
                    <div class="label">Automunito</div>
                    <label class="switch">
                        <input type="checkbox" :name="name" :class="[inputState, noIcon]"
                            :disabled="(listEdit) ? false : true" :value="checked" v-model="got_car">
                        <span class="slider"><span class="yes">Sì</span></span>
                    </label>
                </div>
                <FormInput id="tot-hours" :disabled="(listEdit) ? false : true" v-model:value="num_hours"
                    labelText="Monte ore" />
            </div>
            <!--<FormSelect labelText="Giorno Off" :disabled="(listEdit) ? false : true" :week="true" v-model:value="dayOff" :value="options.day_off" />-->
            <div class="input-container">
                <label>Riposo</label>
                <div class="radio-container">
                    <div v-for="i in 7" :key="i" class="radio-day-off">
                        <FormRadio :index="i" v-model:value="daysOff[dayValue(i)]" :error="(daysOffDate == dayValue(i)) ? true : false" @click="$emit('daysOff', daysOff)"/>
                        <div class="day">
                            {{ day(i) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="daysOffError" class="error">
            {{ errorMessage }}
        </div>
        <div class="notes-container">
            <div class="title">Note</div>
            <Button v-if="edit" class="add-notes" id="add" buttonType="brand" rounded="true" text="Aggiungi una nota" icon="close" status="" @click="addNoteField()" />
        </div>
        <Notes ref="notesField" v-for="(note, index) in formNotes" :id="index" :disabled="(listEdit) ? false : true"
            @deleteNote="deleteNote(index)" @saveNote="saveNote(index)" @editNote="editNote(index)" :icons="icons(index)"
            :visible="(!listEdit) ? false : true" icon="delete" :key="index" v-model:value="message[index]" class="note-field" />
    </div>
</template>

<script>

import FormInput from '../tools/FormInput.vue';
//import FormSelect from '../tools/FormSelect.vue';
import Button from '../tools/Button.vue';
import Notes from '../Notes.vue';
import NotesService from '../../services/notesService';
import FormRadio from '../tools/FormRadio.vue';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import { setEmptyDaysOff } from '../../setters';
import dayjs from 'dayjs';


const valid_phone = helpers.regex(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\|\s)?(\d{3})(\|\s)?(\d{3,6})$/)

export default {
    name: 'worker-details-form',
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    components: {
        FormRadio,
        FormInput,
        //FormSelect,
        Notes,
        Button,
    },
    props: {
        daysOffList: {
            default: null,
            type: Object
        },
        daysOffDate: {
            default: '',
            type: String
        },
        daysOffError: {
            default: false,
            type: Boolean
        },  
        edit: {
            default: false,
            type: Boolean
        },
        errorMessage: {
            default: '',
            type: String
        },
        listEdit: {
            default: null,
            type: Boolean
        },
        id: {
            default: true,
            type: Boolean
        },
        options: {
            app_access: {
                default: false,
                type: Boolean
            },
            name: {
                default: '',
                type: String
            },
            surname: {
                default: '',
                type: String
            },
            badge_number: {
                default: '',
                type: String
            },
            email: {
                default: null,
                type: String
            },
            phone: {
                default: '',
                type: String
            },
            address: {
                default: '',
                type: String
            },
            city: {
                default: '',
                type: String
            },
            totHours: {
                default: null,
                type: Number
            },
            got_car: {
                default: null,
                type: Boolean
            },
        },
        notes: {
            default: null,
            type: Array
        },
        scope: {
            deafult: '',
            type: String
        },
        visible: {
            default: true,
            type: Boolean
        },
        notesIcons : {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            app_access: (this.options.app_access !== null) ? (this.options.app_access == 1) ? true : false : false,
            name: (this.options.name !== '') ? this.options.name : '',
            badge_number: (this.options.badge_number !== '') ? this.options.badge_number : '',
            surname: (this.options.surname !== '') ? this.options.surname : '',
            email: (this.options.email !== null) ? this.options.email : null,
            phone: (this.options.phone !== '') ? this.options.phone : '',
            address: (this.options.address !== '') ? this.options.address : '',
            city: (this.options.city !== '') ? this.options.city : '',
            num_hours: (this.options.num_hours !== '') ? this.options.num_hours : '',
            got_car: (this.options.got_car !== null) ? (this.options.got_car == 1) ? true : false : false,
            daysOff: (this.daysOffList !== null) ? this.daysOffList : {
                'monday': 0,
                'tuesday': 0,
                'wednesday': 0,
                'thursday': 0,
                'friday': 0,
                'saturday': 0,
                'sunday': 0,
            },
            formNotes: null,
            message: []
        }
    },
    computed: {
        notesList() {
            if(this.notes !== null){
                return this.notes
            }else{
                return []
            }
        },
    },
    methods: {
        async validate() {
            return await this.v$.$validate()
        },
        addNoteField() {
            this.formNotes++
            this.message.push('')
        },
        async deleteNote(index) {

            await NotesService.deleteNote(localStorage.getItem('token'), this.notesList[index].id)
        },
        async saveNote(index) {

            var noteData = {
                "message": this.message[index],
                "datetime": dayjs().format('DD/MM/YYYY'),
                "entity_type": 'user',
                "user_id": this.options.id,
                "shop_id": null
            }

            var newNote = await NotesService.addNote(localStorage.getItem('token'), noteData)

            this.notesList.push(newNote)
        },
        async editNote(index) {

            var noteData = {
                "message": this.message[index],
                "datetime": dayjs().format('DD/MM/YYYY'),
                "entity_type": 'user',
                "user_id": this.options.id,
                "shop_id": null
            }

            this.notesList[index].message = this.message[index]

            await NotesService.updateNote(localStorage.getItem('token'), this.notes[index].id, noteData)

            var textarea = document.querySelectorAll('.note-field')[index]

            textarea.classList.add('blur')

            setTimeout(() => {
                textarea.classList.remove('blur')
            }, 1500)

        },
        icons(index) {
            if(this.notesIcons == true){
                if (this.notes !== null && this.notes.length > 0) {
                    
                    if (this.notes[index]) {
                        return 1
                    } else {
                        return 2
                    }
                }
            }else{
                return 0
            }
        },
        day(number){
            return dayjs().day(number).locale('it').format('dd').substring(0, 1)
        },
        dayValue(number){
            var day = dayjs().day(number).format('dddd')
            day = day.toLowerCase()
            return day
        }
    },
    watch: {
        notes(value) {
            if (value !== null && value.length > 0) {
                this.message = []
                value.map((note) => {
                    this.message.push(note.message)
                })
                this.formNotes = value.length
            } else {
                this.message = []
                this.formNotes = 1
            }

            this.notesList = value
        },
        daysOffList(value){
            if(value !== null){
                this.daysOff = value
            }else{
                this.daysOff = setEmptyDaysOff()
            }
        }
    },
    validations() {
        return {
            name: { required: helpers.withMessage('Il nome è obbligatorio', required) },
            surname: { required: helpers.withMessage('Il cognome è obbligatorio', required) },
            email: { email: helpers.withMessage('L\'email inserita non è corretta', email) },
            phone: { validPhone: helpers.withMessage('Il numero inserito non è corretto', valid_phone) }
        }
    },
    mounted() {
        if (this.notesList !== null && this.notesList.length > 0) {
            this.notesList.map((note) => {
                this.message.push(note.message)
            })
            this.formNotes = this.notesList.length
        } else {
            this.message = []
            this.formNotes = 1
        }
    }
}

</script>