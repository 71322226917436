export const setWorker = (data, status, contract, role) => {

    var worker = {}

    worker.app_access = data.app_access,
    worker.full_name = data.name + ' ' + data.surname,
    worker.badge_number = (data.badge_number) ? data.badge_number : null,
    worker.name = data.name,
    worker.surname = data.surname,
    worker.email = data.email,
    worker.got_car = (data.got_car) ? 1 : 0,
    worker.num_hours = (data.num_hours) ? data.num_hours : null,
    worker.phone = data.phone,
    worker.address = data.address,
    worker.city = data.city,
    worker.status = status,
    worker.email_verified_at = null,
    worker.contract = contract,
    worker.role = role

    return worker

}

export const setShop = (data, status) => {

    var shop = {}

    shop.full_name = data.full_name,
    shop.inspector_id = data.inspector_id,
    shop.director_name = data.director_name,
    shop.director_phone = data.director_phone,
    shop.address = data.address,
    shop.city = data.city,
    shop.group_id = data.group_id,
    shop.num_monthly_hours = data.num_monthly_hours,
    shop.status = status

    return shop
}

export const setEmptyDaysOff = () => {
    return {
        'monday': 0,
        'tuesday': 0,
        'wednesday': 0,
        'thursday': 0,
        'friday': 0,
        'saturday': 0,
        'sunday': 0,
    }
}
