<template>
    <div class="qr-code-generator">
        <div class="title">Qr Code</div>
        <vue3-html2pdf :show-layout="false" :float-layout="true" :enable-download="true"
            :preview-modal="true" :paginate-elements-by-height="120" :filename="fileName" :pdf-quality="2"
            :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="100%"
            ref="qrCodePdf" @hasDownloaded="hasDownloaded">
            <template v-slot:pdf-content>
                <QrCodeTemplate id="qrCode" :shop_options="shop_options" />
            </template>
        </vue3-html2pdf>
        <Button text="Scarica QR code" icon="download" :rounded="true" @click="download()" />
    </div>
</template>

<script>


import Button from './tools/Button.vue'
import Vue3Html2pdf from 'vue3-html2pdf';
import QrCodeTemplate from '../components/QrCodeTemplate.vue'

export default {
    name : 'qr-code-generator',
    components : {
        Button,
        QrCodeTemplate,
        Vue3Html2pdf
    },
    props : {
        shop_options : {
            default: null,
            type: String
        }
    },
    methods : {
        download(){
            this.$refs.qrCodePdf.generatePdf()
        }
    }
}

</script>